import { useCoursesStore } from '~~/stores/courses'

export default defineNuxtRouteMiddleware((to) => {
  const router = useRouter()
  const coursesStore: Record<string, any> = useCoursesStore()
  const courseDetail = computed(() => coursesStore?.state?.courseDetail)

  coursesStore?.fetchCourseDetail({
    payload: {
      slugCourse: to?.params?.slug
    },
    resolve: () => {
      router.replace(`/kursus/${courseDetail.value?.data?.urlSlug}`)
    }
  })
})
